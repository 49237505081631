<template>
  <v-card>
    <v-card>
      <v-card-text>
        <div>
          <h2 style="margin-bottom:12px;">Link</h2>
          <v-text-field
            v-model="link"
            placeholder="https://amazon.com/.."
          ></v-text-field>
          <h2 style="margin-bottom:12px;">Categorty</h2>
          <v-select
            item-text="name"
            item-value="abbreviation"
            :items="categories()"
            v-model="category"
          />
          <h2 style="margin-bottom:12px;">Subcategorty</h2>
          <v-select
            item-text="name"
            item-value="abbreviation"
            :items="subcategories(category)"
            v-model="subcategory"
          />
          <br />
          <br />
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-4 main-btn success-green" text @click="save()">
          Save
        </v-btn>
        <v-btn
          class="mr-4 main-btn"
          outlined
          color="gray"
          text
          @click="cancel()"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
    </v-card>
  </v-card>
</template>

<script>
/* eslint-disable no-debugger */
import API from '../../api';
import merchandiseCategoryJSON from '../../../data/merchantCategories.json';

export default {
  name: 'WorkHour',
  props: {
    merchandise: {
      type: [Object],
    },
  },
  data: () => ({
    //    tags: [],
    error: null,
    link: '',
    category: '',
    subcategory: '',
    //    tagsMap: {},
  }),
  watch: {
    category(newCategory) {
      if (!this.subcategory || this.subcategory == '') {
        return;
      }
      if (!newCategory || newCategory == '') {
        return;
      }
      let categories = this.subcategories(newCategory);
      let found = false;
      for (let index = 0; index < categories.length; index++) {
        const subcategory = categories[index];
        if (subcategory.abbreviation == this.subcategory) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.subcategory = null;
      }
    },
  },
  async created() {
    this.category = this.merchandise.category;
    this.subcategory = this.merchandise.subcategory;
    this.link = this.merchandise.link;
  },
  methods: {
    subcategories(categoryAbbrevation) {
      let categories = [];
      for (let index = 0; index < merchandiseCategoryJSON.length; index++) {
        const category = merchandiseCategoryJSON[index];
        if (category.abbreviation == categoryAbbrevation) {
          return category.subcategories;
        }
      }
      return categories;
    },
    categories() {
      return merchandiseCategoryJSON;
    },
    async save() {
      let res = await API.post('merchandise', {
        id: this.merchandise.id,
        category: this.category,
        link: this.link,
        subcategory: this.subcategory,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.error = null;
        this.$emit('saved');
      }
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.dow {
  width: 60px;
  display: inline-block;
}
.dowCheckbox {
  margin-top: 0px;
}
</style>
