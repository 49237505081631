<template>
  <v-container>
    <v-card flat>
      <v-card outlined>
        <v-card-text class="text-center">
          <div class="greenText" style="font-size:18px;">
            Here are a few products we Love at Whole Life Goals.
          </div>
          <div style="color:gray;font-size:8px;">
            Whole Life Goals receives a commission for purchases made through
            these Amazon affiliate links below.
          </div>
        </v-card-text>
      </v-card>
      <v-tabs color="black" centered>
        <v-tab
          @click="currentCategory = category"
          v-for="category in categoryMerchandiseList"
          :key="category.abbreviation"
        >
          {{ category.name }}
        </v-tab>
      </v-tabs>
      <template v-for="subcategory in currentCategory.merchandise">
        <div v-if="subcategory.merchandise.length" :key="subcategory.id">
          <v-card-title class="greenText">
            {{ subcategory.name }}
          </v-card-title>
          <v-card-text>
            <span
              class="mr-4"
              v-for="merchandise in subcategory.merchandise"
              :key="'merchandise' + merchandise.id"
              v-html="merchandise.iframe"
            >
            </span>
          </v-card-text>
        </div>
      </template>
    </v-card>
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
  </v-container>
</template>
<script>
/* eslint-disable no-debugger */
import { formatName } from '@/util/stringUtils';

import API from '../../api';
import merchandiseCategoryJSON from '../../../data/merchantCategories.json';

export default {
  name: 'public-merchandise-list',
  props: {},
  data: () => ({
    currentCategory: {},
    merchandise: [],
    categoryMerchandiseList: [],
    error: null,
  }),
  created: function() {
    this.loadData(this.tab);
  },
  mounted() {
    document.title = 'Products';
  },
  watch: {
    tab: {
      handler(value) {
        this.loadData(value);
      },
      deep: true,
    },
  },
  methods: {
    formatName(name) {
      return formatName(name);
    },
    async loadData() {
      let res = await API.get('merchandise/public');
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        return;
      }
      this.merchandise = res.data.merchandise;
      let categoryMerchandiseList = [];
      let categories = this.categories();
      for (let index = 0; index < categories.length; index++) {
        const category = categories[index];
        let categoryMerchandise = {
          name: category.name,
          abbreviation: category.abbreviation,
          merchandise: [],
        };
        for (let index = 0; index < category.subcategories.length; index++) {
          let subcategory = category.subcategories[index];
          let merch = this.categoryMerchandise(
            category.abbreviation,
            subcategory.abbreviation
          );
          if (merch && merch.length) {
            let subcategoryMerchandise = {
              name: subcategory.name,
              abbreviation: subcategory.abbreviation,
              merchandise: merch,
            };
            categoryMerchandise.merchandise.push(subcategoryMerchandise);
          }
        }
        if (categoryMerchandise.merchandise.length) {
          categoryMerchandiseList.push(categoryMerchandise);
        }
      }
      this.categoryMerchandiseList = categoryMerchandiseList;
      this.currentCategory = this.categoryMerchandiseList[0];
    },
    categoryMerchandise(category, subcategory) {
      let res = [];
      for (let index = 0; index < this.merchandise.length; index++) {
        const merch = this.merchandise[index];
        if (merch.category === category && merch.subcategory === subcategory) {
          res.push(merch);
        }
      }
      return res;
    },
    subcategories() {
      let categories = [];
      if (this.currentCategory) {
        for (let index = 0; index < merchandiseCategoryJSON.length; index++) {
          const category = merchandiseCategoryJSON[index];
          if (category.abbreviation === this.currentCategory.abbreviation) {
            return category.subcategories;
          }
        }
      }
      return categories;
    },
    categories() {
      return merchandiseCategoryJSON;
    },
  },
};
</script>
/* eslint-disable no-unused-vars */
