<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto">
          <v-card-text>
            Text:
            <v-text-field
              v-model="text"
              placeholder="Text"
              required
            ></v-text-field>
            Price (USD):
            <v-text-field
              v-model="price"
              placeholder="Price"
              required
            ></v-text-field>
            Hidden:
            <v-checkbox
              v-model="hidden"
              placeholder="Hidden"
              required
            ></v-checkbox>
            Order Index:
            <v-text-field
              v-model="orderIndex"
              placeholder="Order Index"
              required
            ></v-text-field>
            Subscription duration in days (add a couple of days just in case):
            <v-text-field
              v-model.number="duration"
              placeholder="Duration"
              required
            ></v-text-field>
            Stripe Price Identifier (Should be created in stripe dashboard):

            <v-text-field
              v-model="stripePriceIdentifier"
              placeholder="Stripe price identifier"
              required
            ></v-text-field>
            Categories:
            <v-autocomplete
              v-model="selectedCategories"
              :items="info.categories"
              chips
              item-text="text"
              item-value="id"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeCategory(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-btn color="success" class="mr-4" @click="saveSubscriptionType()">
              Save
            </v-btn>

            <v-dialog
              v-if="subscriptionTypeId"
              v-model="dialog"
              persistent
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="error" class="mr-4" v-bind="attrs" v-on="on">
                  Delete
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">
                  Delete subscription type?
                </v-card-title>
                <v-card-text>This operation can not be undone</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    class="mr-4"
                    text
                    @click="(dialog = false), deleteSubscriptionType()"
                  >
                    Delete
                  </v-btn>
                  <v-btn color="green darken-1" text @click="dialog = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Import Vue FilePond

/* eslint-disable no-debugger */

import API from '../../api';

/* eslint-disable-next-line  */

export default {
  name: 'Profile',
  props: {
    subscriptionTypeId: {
      type: [String],
    },
  },

  components: {},

  data: () => ({
    error: null,
    info: {},
    categories: {},
    selectedCategories: [],
    text: '',
    price: '1',
    duration: 32,
    sent: false,
    dialog: false,
    hidden: false,
    orderIndex: false,
    stripePriceIdentifier: '',
  }),
  //LOAD
  created: async function() {
    this.error = null;
    let res = await API.get(
      'admin/subscriptionType?subscriptionTypeId=' +
        encodeURI(this.subscriptionTypeId)
    );
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.error = null;
      this.info = res.data.info;

      if (this.info.stripeSubscriptionType) {
        this.text = this.info.stripeSubscriptionType.text;
        this.price = this.info.stripeSubscriptionType.price;
        this.stripePriceIdentifier = this.info.stripeSubscriptionType.stripePriceIdentifier;
        this.selectedCategories = this.info.stripeSubscriptionType.categoryIds;
        this.duration = this.info.stripeSubscriptionType.duration;
        this.hidden = this.info.stripeSubscriptionType.hidden;
        this.orderIndex = this.info.stripeSubscriptionType.orderIndex;
      }

      for (let index = 0; index < res.data.info.categories.length; index++) {
        const category = res.data.info.categories[index];
        this.categories[category.id] = category;
      }
    }
  },
  computed: {},
  methods: {
    removeCategory(item) {
      const index = this.selectedCategories.indexOf(item.id);
      if (index >= 0) this.selectedCategories.splice(index, 1);
    },
    async saveSubscriptionType() {
      let res = await API.post('admin/subscriptionType', {
        stripeSubscriptionTypeId: this.subscriptionTypeId,
        categoryIds: this.selectedCategories,
        stripePriceIdentifier: this.stripePriceIdentifier,
        price: this.price,
        text: this.text,
        duration: this.duration,
        hidden: this.hidden,
        orderIndex: this.orderIndex,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        this.error = null;
        this.sent = true;
        this.$router.push('/admin/subscription-types');
      }
    },
    async deleteSubscriptionType() {
      let res = await API.delete('admin/subscriptionType', {
        stripeSubscriptionTypeId: this.subscriptionTypeId,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        this.sent = false;
      } else {
        this.error = null;
        this.sent = true;
        this.$router.push('/admin/subscription-types');
      }
    },
  },
};
</script>

<style></style>
