<template class="main-container">
  <v-container>
    <v-row class="main-container">
      <v-col>
        <v-expansion-panels accordion v-model="panel" max-width="1000">
          <v-expansion-panel
            v-for="(item, index) in shownQuestions"
            :key="index"
            :value="index"
          >
            <v-expansion-panel-header
              class="list-title"
              style="text-transform: uppercase"
              ><template v-slot:actions>
                <v-icon>
                  {{ panel != index + 2 ? 'mdi-plus' : 'mdi-close' }}
                </v-icon> </template
              >{{ item.title }}</v-expansion-panel-header
            >

            <v-expansion-panel-content>
              <ask-questions
                :currentQuestion="item ? item : {}"
                @nextSection="nextSection()"
                @cancelAskQuestion="cancelAskQuestion"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="list-title">
              <template v-slot:actions>
                <v-icon>
                  {{ panel != 0 ? 'mdi-plus' : 'mdi-close' }}
                </v-icon>
              </template>
              EDIT YOUR ACCOUNT</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <EditAccount @nextSection="nextSection()" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :value="shownQuestions.length">
            <v-expansion-panel-header class="list-title">
              <template v-slot:actions>
                <v-icon>
                  {{ panel != 1 ? 'mdi-plus' : 'mdi-close' }}
                </v-icon> </template
              >GENERAL INFORMATION</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <EditProfileInfo
                :section="sections().MEMBER"
                @nextSection="nextSection()"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable no-debugger */

import EditAccount from './EditAccount';
import EditProfileInfo from './EditProfileInfo';
import questionsStore from '@/components/member/questionsStore';
import EditProfileSections from './EditProfileSections';

export default {
  name: 'EditProfileTabbar',
  props: {},
  data: () => ({
    tab: '',
    panel: 0,
  }),
  async created() {
    if (this.$route.query.panel) {
      this.panel = parseInt(this.$route.query.panel);
    }
    questionsStore.created();
  },
  components: {
    EditAccount,
    EditProfileInfo,
  },
  methods: {
    isProfessional() {
      return localStorage.getItem('isProfessional') == 'true' ? true : false;
    },
    sections() {
      return EditProfileSections;
    },
    nextSection() {
      setTimeout(() => {
        this.panel = (this.panel + 1) % (this.shownQuestions.length + 1);
      }, 300);
    },
    async cancelAskQuestion() {
      await questionsStore.created();
      this.panel = -1;
    },
  },
  computed: {
    shownQuestions() {
      return questionsStore.shownQuestions;
    },
  },
};
</script>
<style>
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.name-profile {
  font-size: 24px;
}
.title-text {
  font-size: 15px !important;
}
.blue-text {
  letter-spacing: 0.3px;
  opacity: 0.8;
  color: #6596b2;
  font-size: 15px;
  font-weight: 700;
}
.bolder {
  font-weight: bolder;
}
.avatar2 {
  width: 140px !important;
  height: 140px !important;
  top: 0px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.filepond--item {
  width: calc(50% - 0.5em);
}
.v-icon.outlined {
  background-color: white;
  height: 50px;
  width: 50px;
}
.img {
  background: rgba(0, 0, 0, 0.5);
  height: 400px;
  cursor: pointer;
}
.text-image {
  color: #fff;
  text-shadow: 0 1px #666;
  cursor: pointer;
  line-height: 21px;
  font-size: 16px;
}
.v-textarea textarea {
  max-height: none;
  height: 32px;
}
.list-title {
  color: #428751;
  font-family: 'Nunito', sans-serif !important;
  font-weight: bold;
  font-size: 18px !important;
  /*border-color: unset !important;*/
  border-bottom: 1px solid #ffffff;
}
</style>
