<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <h2 class="title mb-2">
              Logout
            </h2>

            <v-form ref="form">
              <v-alert type="success">
                You were logged out
              </v-alert>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */
import loginStorage from '../../util/loginStorage';

export default {
  name: 'ResetPassword',

  data: () => ({
    //    tags: [],
    error: null,
  }),
  mounted() {
    document.title = 'Logout';
  },

  created: async function() {
    loginStorage.logout();
    setTimeout(() => {
      this.$router.push('login');
    }, 1000);
  },
  methods: {},
  computed: {},
};
</script>
<style></style>
