<template>
  <v-combobox
    v-model="select"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    cache-items
    hide-no-data
    clearable
    hide-details
    item-text="name"
    item-value="name"
    outlined
    return-object
    append-icon=""
  ></v-combobox>
</template>

<script>
/* eslint-disable no-debugger */
import API from '../../api';

/* eslint-disable-next-line  */

export default {
  name: 'Subscribe',

  props: {
    searchZipcode: {
      type: Boolean,
    },
  },
  data: () => ({
    loading: false,
    items: [],
    search: null,
    select: null,
    //    tags: [],
    error: null,
  }),
  async created() {
    this.value = this.zipcode;
  },
  computed: {},
  watch: {
    search(val) {
      if (val && val !== this.select) {
        this.querySelections(val);
      }
    },
    select(val) {
      if (typeof val === 'string') {
        this.$emit('selectedZipOrCity', {
          name: val,
          type: this.searchZipcode ? 'zipcode' : 'city',
        });
      } else {
        this.$emit('selectedZipOrCity', val);
      }
    },
  },
  methods: {
    async querySelections(val) {
      this.loading = true;
      // Simulated ajax query
      try {
        let res = await API.get(
          'zipcode/search?text=' +
            encodeURI(val) +
            (this.searchZipcode ? '&zipcode=true' : '')
        );
        if (!res.errors) {
          this.items = [];
          if (res.data.cities) {
            for (let index = 0; index < res.data.cities.length; index++) {
              const element = res.data.cities[index];
              this.items.push({ name: element.city, type: 'city' });
            }
          }
          if (res.data.zipcodes) {
            for (let index = 0; index < res.data.zipcodes.length; index++) {
              const element = res.data.zipcodes[index];
              this.items.push({ name: element.zipcode, type: 'zipcode' });
            }
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.v-list__group__header:hover {
  background-color: blue !important;
}
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
</style>
