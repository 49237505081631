import axios from 'axios';
import Socket from '@/sockets/socket';

export default {
  loginWithData: function(data) {
    localStorage.setItem('jwt', data.token);
    localStorage.setItem('userId', data.user.id);
    localStorage.setItem('isAdmin', data.user.isAdmin);
    localStorage.setItem('isProfessional', data.user.isProfessional);
    localStorage.setItem('username', data.user.username);
    axios.defaults.headers.common['Authorization'] = data.token;
    Socket.authenticate();
  },
  logout: function() {
    localStorage.removeItem('jwt'); // if the request fails, remove any possible user token if possible
    localStorage.removeItem('userId'); // if the request fails, remove any possible user token if possible
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('isProfessional');
    localStorage.removeItem('username');
    axios.defaults.headers.common['Authorization'] = null;
    Socket.authenticate();
  },
  loggedIn() {
    return localStorage.getItem('jwt') ? true : false;
  },
  isAdmin() {
    return localStorage.getItem('isAdmin') == 'true' ? true : false;
  },
  getUsername() {
    return localStorage.getItem('username');
  },
  isProfessional() {
    return localStorage.getItem('isProfessional') == 'true' ? true : false;
  },
};
