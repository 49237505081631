import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import PrayerIcon from '../components/review/PrayerIcon';
import PrayerIconGrayscalled from '../components/review/PrayerIconGrayscalled';
import PrayerIconHalfGrayscalled from '../components/review/PrayerIconHalfGrayscalled';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      prayer: {
        // name of our custom icon
        component: PrayerIcon, // our custom component
      },
      prayerGrayscalled: {
        // name of our custom icon
        component: PrayerIconGrayscalled, // our custom component
      },
      prayerHalfGrayscalled: {
        // name of our custom icon
        component: PrayerIconHalfGrayscalled, // our custom component
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#428751',
        secondary: '#b4ddbd',
        accent: '#2ee055',
        error: '#b71c1c',
      },
    },
  },
});
