export function formatName(name) {
  if (!name) {
    return '';
  }
  let parts = name.split(' ');
  for (let index = 0; index < parts.length; index++) {
    const part = parts[index];
    if (part.length > 0) {
      parts[index] =
        part.substring(0, 1).toUpperCase() + part.substring(1).toLowerCase();
    }
  }
  return parts.join(' ');
}
