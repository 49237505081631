<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="400">
          <v-card-text>
            <h2 class="title mb-2">
              Login
            </h2>

            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                placeholder="E-mail"
                required
              ></v-text-field>

              <v-text-field
                v-model="password"
                :rules="[passwordRules.req, passwordRules.min]"
                name="input-10-1"
                placeholder="Password"
                type="password"
                hint="At least 8 characters"
                counter
              ></v-text-field>

              <v-alert v-if="error" type="error">
                {{ error }}
              </v-alert>
              <v-btn class="mr-4 main-btn success-green" @click="register">
                Login
              </v-btn>

              <v-btn
                class="mr-4 main-btn"
                outlined
                color="gray"
                to="forgot-password"
              >
                Forgot password?
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */

import API from '../../api';

import loginStorage from '@/util/loginStorage';

export default {
  name: 'Login',

  data: () => ({
    error: null,
    valid: false,
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    password: '',
    passwordRules: {
      req: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
    },
  }),
  mounted() {
    document.title = 'Login';
  },

  created: async function() {},
  methods: {
    async register() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let res = await API.post('login', {
        email: this.email,
        password: this.password,
      });
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
        loginStorage.logout();
      } else {
        this.error = null;

        //let is_admin = res.data.user.is_admin;
        try {
          loginStorage.loginWithData(res.data);
        } catch (e) {
          console.log(e);
        }
        if (localStorage.getItem('jwt') != null) {
          this.$emit('loggedIn');
          if (this.$route.params.nextUrl != null) {
            this.$router.push(this.$route.params.nextUrl);
          } else {
            /*if (is_admin == 1) {
              this.$router.push('admin');
            } else {*/
            if (res.data.user.isProfessional) {
              if (res.data.user.other) {
                this.$router.push('/user/' + res.data.user.username);
              } else {
                this.$router.push('/profile/edit');
              }
            } else {
              if (res.data.user.other) {
                this.$router.push('/search');
              }
            }
            //}
          }
        }
      }
    },
  },
  computed: {},
};
</script>
<style>
input {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
