<template>
  <v-container>
    <v-row class="main-container">
      <v-col>
        <v-card class="mx-auto" max-width="1000">
          <v-card-text style="padding-bottom:0px">
            <v-tabs v-model="tab">
              <v-tabs-slider color="#428751"></v-tabs-slider>
              <v-tab key="tab-1" href="#tab-1" active-class="activeTab">
                Match
              </v-tab>
              <v-tab key="tab-2" href="#tab-2" active-class="activeTab">
                Search
              </v-tab>
            </v-tabs>
          </v-card-text>
          <v-card-text style="padding-bottom:0px">
            <v-tabs-items v-model="tab">
              <v-tab-item
                style="height:80px;"
                key="tab-2"
                value="tab-2"
                :transition="false"
                :reverse-transition="false"
              >
                Know what you want?
                <v-text-field
                  placeholder="Example: Personal Trainer"
                  hide-details
                  clearable
                  outlined
                  style="max-width:400px"
                  v-model="searchText"
                  @click:append="search"
                ></v-text-field>
              </v-tab-item>
              <v-tab-item
                style="height:80px;"
                key="tab-1"
                value="tab-1"
                :transition="false"
                :reverse-transition="false"
              >
                Let us match you with a professional for greater success.
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-text>
            Zip Code
            <span
              ><zipcodeorcity
                :searchZipcode="true"
                style="max-width:400px"
                @selectedZipOrCity="selectedZipOrCity"
            /></span>
            <br />
            <br />
            City
            <zipcodeorcity
              :searchZipcode="false"
              style="max-width:400px"
              @selectedZipOrCity="selectedZipOrCity"
            />
            State
            <v-autocomplete
              v-model="selectedState"
              hide-details
              clearable
              outlined
              style="max-width:400px"
              :items="states()"
              item-text="name"
              item-value="abbreviation"
            >
            </v-autocomplete>
          </v-card-text>

          <v-card-text>
            <v-expansion-panels
              outlined
              accordion
              v-model="panel"
              max-width="1000"
            >
              <template v-if="tab == 'tab-1'">
                <v-expansion-panel
                  v-for="(item, index) in shownQuestions"
                  :key="index"
                  :value="index"
                >
                  <v-expansion-panel-header
                    class="list-title"
                    style="text-transform: uppercase"
                    ><template v-slot:actions>
                      <v-icon>
                        {{ panel != index + 1 ? 'mdi-plus' : 'mdi-close' }}
                      </v-icon> </template
                    >{{ item.title }}</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <ask-questions
                      :currentQuestion="item"
                      @nextSection="nextSection()"
                      @cancelAskQuestion="cancelAskQuestion"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
              <v-expansion-panel :value="shownQuestions.length">
                <v-expansion-panel-header class="list-title"
                  ><template v-slot:actions>
                    <v-icon>
                      {{ panel != 0 ? 'mdi-plus' : 'mdi-close' }}
                    </v-icon> </template
                  >GENERAL INFORMATION</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <EditProfileInfo
                    :section="sections().MEMBER"
                    @nextSection="nextSection()"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>

          <v-card-text>
            <v-btn
              class="mr-4 main-btn success-green"
              @click="search()"
              id="searchId"
            >
              Search
            </v-btn>
          </v-card-text>

          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>
          <v-card-text v-for="category in shownCategories()" :key="category.id">
            <CategorySearchResult
              :users="categoryUsers[category.id]"
              :category="category"
              :tags="tags"
            />
          </v-card-text>
          <v-dialog v-model="dialog" max-width="320">
            <v-card class="pa-1">
              <v-card-title class="headline">
                No professionals found
              </v-card-title>
              <v-card-text>
                Thank you for becoming a Whole Life Goals Member. We are beta
                testing the site now while certified professionals continue to
                join.
              </v-card-text>
              <v-card-text>
                Please try broadening your filters or in Preferred Location
                change to Web Conferencing only.
              </v-card-text>
              <v-card-text>
                If you have any questions please contact:
                info@WholeLifeGoals.com
              </v-card-text>
              <v-card-text>
                With gratitude,
              </v-card-text>
              <v-card-text>
                The Whole Life Goals Team
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="mr-4 main-btn success-green"
                  text
                  @click="dialog = false"
                >
                  Ok
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
              <v-alert v-if="errorDialog" type="error">
                {{ errorDialog }}
              </v-alert>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-debugger */
import EditProfileSections from '../profile/EditProfileSections';

import API from '../../api';
import CategorySearchResult from './CategorySearchResult';
import EditProfileInfo from '../profile/EditProfileInfo';
import questionsStore from '@/components/member/questionsStore';
import goTo from 'vuetify/lib/services/goto';
import statesJSON from '../../../data/states.json';

export default {
  name: 'Search',

  components: {
    EditProfileInfo,
    CategorySearchResult,
    //Matching,
    //Tags,
  },
  data: () => ({
    tags: [],
    error: null,
    profile: {},
    searchText: '',
    panel: -1,
    oldPanel: 0,
    users: [],
    tab: '',
    dialog: false,
    zipcode: '',
    categories: [],
    categoryUsers: {},
    selectedZipObject: {},
    preferredHome: false,
    preferredClient: false,
    preferredOnline: false,
    preferredAgreedUpon: false,
    free15: false,
    workWeekends: false,
    selectedState: '',
    errorDialog: false,
  }),
  mounted() {
    document.title = 'Search';
  },
  async created() {
    questionsStore.created();
    this.error = null;
    let res = await API.get('profile');
    if (!res) {
      return;
    }
    if (res.errors && res.errors.length > 0) {
      this.error = res.errors[0].msg;
    } else {
      this.categories = res.data.categories;
      this.tags = res.data.tags;
    }
  },
  methods: {
    states() {
      return statesJSON;
    },
    selectedZipOrCity(val) {
      this.selectedZipObject = val;
    },
    sections() {
      return EditProfileSections;
    },
    shownCategories() {
      let res = [];
      for (let index = 0; index < this.categories.length; index++) {
        const category = this.categories[index];
        if (this.categoryUsers[category.id]) {
          res.push(category);
        }
      }
      return res;
    },
    onMatchTab() {
      return this.tab == 'tab-1';
    },
    async search() {
      setTimeout(() => {
        this.doSearch();
      }, 100);
    },
    async doSearch() {
      var searchParameters = {};
      if (!this.onMatchTab()) {
        searchParameters.text = this.searchText;
      }
      if (this.selectedZipObject) {
        if (this.selectedZipObject.type == 'city') {
          searchParameters.city = this.selectedZipObject.name;
        } else {
          searchParameters.zipcode = this.selectedZipObject.name;
        }
      }
      if (this.selectedState) {
        searchParameters.state = this.selectedState;
      }

      let res = await API.post('search', searchParameters);
      if (res.errors && res.errors.length > 0) {
        this.error = res.errors[0].msg;
      } else {
        this.error = null;
        this.users = res.data.users;
        this.categoryUsers = {};
        if (!res.data.users.length) {
          this.dialog = true;
          return;
        }
        for (let index = 0; index < res.data.users.length; index++) {
          const user = res.data.users[index];
          for (let index2 = 0; index2 < user.userCategories.length; index2++) {
            let userCategory = user.userCategories[index2];
            let users = this.categoryUsers[userCategory.categoryId];
            if (!users) {
              users = [];
              this.categoryUsers[userCategory.categoryId] = users;
            }
            users.push(user);
          }
        }
      }
      goTo('#searchId');
    },
    async matchProfile() {
      this.dialog = true;
    },
    random() {
      return '' + Math.random();
    },
    showCurrentFiler() {
      return true;
    },
    nextSection() {
      setTimeout(() => {
        this.panel = (this.panel + 1) % (this.shownQuestions.length + 1);
      }, 300);
    },
    async cancelAskQuestion() {
      await questionsStore.created();
      this.panel = -1;
    },
  },

  computed: {
    shownQuestions() {
      return questionsStore.shownQuestions;
    },
  },
};
</script>

<style scoped>
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.activeTab {
  color: #428751 !important;
}
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
.tabs .indicator {
  background-color: #428751 !important;
} /*Color of underline*/
</style>
