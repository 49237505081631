<template>
  <v-card outlined style="border-color:green" v-if="users && users.length > 0">
    <v-card-text class="text-h5 pb-0">
      <v-btn outlined rounded color="primary" dark>{{ category.name }}</v-btn>
      <v-spacer></v-spacer>
    </v-card-text>

    <v-list light>
      <template v-for="(item, index) in limitedUsers()">
        <v-list-item :key="item.username" :to="'user/' + item.username">
          <v-list-item-content style="border-color:green">
            <v-card outlined style="border-color:green">
              <div class="d-flex flex-no-wrap flex-row">
                <div>
                  <v-avatar
                    class="ma-2 pb-0"
                    :size="avatarSize"
                    style="border: 1px solid;"
                  >
                    <v-icon x-large v-if="!item.profileImageUrl">
                      mdi-account-circle
                    </v-icon>
                    <v-img
                      v-if="item.profileImageUrl"
                      :src="item.profileImageUrl"
                    >
                    </v-img>
                  </v-avatar>
                </div>
                <div>
                  <v-card-text class="pl-0 pb-0">
                    <b>
                      {{ formatName(item.name) }}
                    </b>
                    <div v-html="specialization(item)"></div>
                    <div
                      v-html="
                        approvedTagsByCategory(item.approvedTags, category)
                      "
                    ></div>
                    <div v-html="priceLabel(item)"></div>
                    <v-rating
                      v-if="item.ratingFloat"
                      dense
                      v-model="item.ratingFloat"
                      readonly
                      half-increments
                      empty-icon="$vuetify.icons.prayerGrayscalled"
                      full-icon="$vuetify.icons.prayer"
                      half-icon="$vuetify.icons.prayerHalfGrayscalled"
                    >
                    </v-rating>
                    <div><a>click for full profile</a></div>
                  </v-card-text>
                </div>
              </div>
            </v-card>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-if="index < limitedUsers().length - 1"
          :key="index"
        ></v-divider>
      </template>
    </v-list>

    <v-card-text class="text-h5 pt-0">
      <v-btn
        outlined
        rounded
        color="primary"
        dark
        @click="show = !show"
        v-if="!show && users && users.length > 0"
        ><span style="font-size: 12px">
          See more match results in this category
        </span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable no-debugger */
import { formatName } from '@/util/stringUtils';
import { ApprovedTagsExtractor } from '@/util/approvedTagExtractor';

export default {
  name: 'Profile',
  props: {
    users: {
      type: Array,
    },
    category: {
      type: Object,
    },
    tags: {
      type: Array,
    },
  },
  data: () => ({
    //    tags: [],
    error: null,
    show: false,
  }),
  computed: {
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 80;
        default:
          return 125;
      }
    },
  },
  async created() {},
  methods: {
    approvedTags(user) {
      if (user.specialization) {
        let specialization = user.specialization[this.category.id + ''];
        if (specialization) {
          return specialization;
        } else {
          return user.mainPractice;
        }
      } else {
        return null;
      }
    },
    priceLabel(user) {
      let res = '';
      if (user.appointmentRate) {
        res = '$' + user.appointmentRate + ' per session';
      }
      if (user.typesOfPaymentsAccepted) {
        if (res == '') {
          res = user.typesOfPaymentsAccepted.join(', ');
        } else {
          res = res + ' ' + user.typesOfPaymentsAccepted.join(', ');
        }
      }
      return res;
    },
    specialization(user) {
      if (user.specialization) {
        let specialization = user.specialization[this.category.id + ''];
        if (specialization) {
          return specialization;
        } else {
          return user.mainPractice;
        }
      } else {
        return null;
      }
    },

    formatName(name) {
      return formatName(name);
    },
    limitedUsers() {
      if (this.show) {
        return this.users;
      } else {
        let res = [];
        if (this.users && this.users.length > 0) {
          res.push(this.users[0]);
        }
        if (this.users && this.users.length > 1) {
          res.push(this.users[1]);
        }
        if (this.users && this.users.length > 2) {
          res.push(this.users[2]);
        }
        if (this.users && this.users.length > 3) {
          res.push(this.users[3]);
        }
        if (this.users && this.users.length > 4) {
          res.push(this.users[4]);
        }
        return res;
      }
    },
    approvedTagsByCategory(approvedTags, category) {
      let approvedTagsExtractor = new ApprovedTagsExtractor(
        approvedTags,
        this.tags
      );
      return approvedTagsExtractor.approvedTagsByCategory(category, 3);
    },
  },
};
</script>

<style>
.avatar {
  width: 140px !important;
  height: 140px !important;
  top: -70px !important;
  left: 30px !important;
  border: 5px solid #fff;
}
.v-text-field >>> button {
  font-size: 0.8em;
}
.main-container {
  font-family: 'Nunito', sans-serif !important;
}
</style>
