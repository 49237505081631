import Vue from 'vue';
import Router from 'vue-router';
import Tags from './components/Tags';

import Register from './components/user/Register';
import Login from './components/user/Login';
import Logout from './components/user/Logout';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';
import ConfirmEmail from './components/user/ConfirmEmail';
import Profile from './components/profile/Profile';
import EditProfile from './components/profile/EditProfile';
import EditMemberProfile from './components/profile/EditMemberProfile';
import Search from './components/search/Search';
import AskQuestions from './components/member/AskQuestions';
import Home from './components/home/Home';
import About from './components/home/About';
import HowItWorks from './components/home/HowItWorks';
import AdminDashboard from './components/admin/AdminDashboard';
import AdminTag from './components/admin/AdminTag';
import AdminQuestion from './components/admin/AdminQuestion';
import AdminUsers from './components/admin/AdminUsers';
import AdminQuestions from './components/admin/AdminQuestions';
import AdminTags from './components/admin/AdminTags';
import AdminCategories from './components/admin/AdminCategories';
import AdminCategory from './components/admin/AdminCategory';
import AdminLoginAs from './components/admin/AdminLoginAs';
import AdminSubscriptionTypes from './components/admin/AdminSubscriptionTypes';
import AdminMerchandise from './components/admin/AdminMerchandise';
import AdminSubscriptionType from './components/admin/AdminSubscriptionType';
import StripeCancel from './components/stripe/StripeCancel';
import StripeSuccess from './components/stripe/StripeSuccess';
import StripeSubscriptions from './components/stripe/StripeSubscriptions';
import CreateAppointment from './components/appointment/CreateAppointment';
import AppointmentsList from './components/appointment/AppointmentsList';
import Appointment from './components/appointment/Appointment';
import RescheduleAppointment from './components/appointment/RescheduleAppointment';
import ZipcodeOrCity from './components/member/ZipcodeOrCity';
import Chat from './components/chat/Chat';
import ChatsList from './components/chat/ChatsList';
import ReviewsList from './components/review/ReviewsList';
import Merchandise from './components/merchandise/PublicMerchandiseList';
import loginStorage from '@/util/loginStorage';

/* eslint-disable no-debugger */

Vue.use(Router);
Vue.component('ask-questions', AskQuestions);
Vue.component('zipcodeorcity', ZipcodeOrCity);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/products',
      name: 'Merchandise',
      component: Merchandise,
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
    },
    {
      path: '/about-wlg',
      name: 'About',
      component: About,
    },
    {
      path: '/how-it-works',
      name: 'HowItWorks',
      component: HowItWorks,
    },
    {
      path: '/tags',
      name: 'tags',
      component: Tags,
      meta: { noAuth: true },
    },
    {
      path: '/register/:registrationType',
      name: 'register',
      component: Register,
      props: true,
      meta: { noAuth: true },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { noAuth: true },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: { noAuth: true },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: { noAuth: true },
    },
    {
      path: '/confirm-email',
      name: 'confirm-email',
      component: ConfirmEmail,
      meta: { noAuth: true },
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: { noAuth: true },
    },
    {
      path: '/search',
      name: 'Search',
      component: Search,
      meta: { noAuth: true },
    },
    {
      path: '/profile/member',
      name: 'edit-member',
      component: EditMemberProfile,
      props: true,
      meta: { noAuth: true },
    },
    {
      path: '/profile/edit',
      name: 'edit',
      component: EditProfile,
      props: true,
      meta: { noAuth: true },
    },
    {
      path: '/admin/tag/new',
      name: 'admin-tag-create',
      component: AdminTag,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/admin/tag/:tagId',
      name: 'admin-tag',
      component: AdminTag,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/admin/question/new',
      name: 'admin-question-create',
      component: AdminQuestion,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/admin/question/:questionId',
      name: 'admin-question',
      component: AdminQuestion,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/admin/loginAs/:username',
      name: 'loginAs',
      component: AdminLoginAs,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/admin/category/new',
      name: 'admin-category-create',
      component: AdminCategory,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/admin/category/:categoryId',
      name: 'admin-category',
      component: AdminCategory,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/admin/subscription-type/new',
      name: 'admin-subscription-type-new',
      component: AdminSubscriptionType,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/admin/subscription-type/:subscriptionTypeId',
      name: 'admin-subscription-type',
      component: AdminSubscriptionType,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/admin',
      name: 'admin',
      component: AdminDashboard,
      props: true,
      meta: { noAuth: false },
      children: [
        {
          path: '',
          redirect: 'users', // default child path
        },
        {
          path: 'tags',
          name: 'admin-tags',
          component: AdminTags,
          props: true,
          meta: { noAuth: false },
        },
        {
          path: 'questions',
          name: 'admin-questions',
          component: AdminQuestions,
          props: true,
          meta: { noAuth: false },
        },
        {
          path: 'users',
          name: 'admin-users',
          component: AdminUsers,
          props: true,
          meta: { noAuth: false },
        },
        {
          path: 'products',
          name: 'admin-merchandise',
          component: AdminMerchandise,
          props: true,
          meta: { noAuth: false },
        },
        {
          path: 'categories',
          name: 'admin-categories',
          component: AdminCategories,
          props: true,
          meta: { noAuth: false },
        },
        {
          path: 'subscription-types',
          name: 'admin-subscription-types',
          component: AdminSubscriptionTypes,
          props: true,
          meta: { noAuth: false },
        },
      ],
    },
    {
      path: '/user/:username',
      name: 'profile-by-username',
      component: Profile,
      props: true,
      meta: { noAuth: true },
    },
    {
      path: '/stripe/success',
      name: 'stripe-success',
      component: StripeSuccess,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/stripe/cancel',
      name: 'stripe-cancel',
      component: StripeCancel,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/subscriptions',
      name: 'stripe-subscriptions',
      component: StripeSubscriptions,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/appointment/create/:username',
      name: 'appointment-create',
      component: CreateAppointment,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/appointment/reschedule/:appointmentUuid',
      name: 'appointment-reschedule',
      component: RescheduleAppointment,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/appointment/list/',
      name: 'appointments-list',
      component: AppointmentsList,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/appointment/:appointmentUuid',
      name: 'appointment',
      component: Appointment,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/chat/:username',
      name: 'chat',
      component: Chat,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/chats',
      name: 'chats',
      component: ChatsList,
      props: true,
      meta: { noAuth: false },
    },
    {
      path: '/reviews/:username',
      name: 'reviews',
      component: ReviewsList,
      props: true,
      meta: { noAuth: false },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      });
    } else {
      let user = JSON.parse(localStorage.getItem('user'));
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (user.is_admin == 1) {
          next();
        } else {
          next({ name: 'home' });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next();
    } else {
      next({ name: 'dashboard' });
    }
  } else {
    if (to.fullPath == '/') {
      if (loginStorage.isProfessional()) {
        router.push('/user/' + loginStorage.getUsername());
      } else {
        router.push('/search');
      }
    } else {
      next();
    }
  }
});

export default router;
