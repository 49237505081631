import axios from 'axios';
import config from '@/config';
import router from '@/router';
import loginStorage from '@/util/loginStorage';

class API {
  constructor() {
    this.backendUrl = config.backendUrl();

    this.loadInitialData();
  }

  loadInitialData() {}

  get(url, data) {
    return this.request('get', url, data);
  }

  post(url, data) {
    return this.request('post', url, data);
  }

  put(url, data) {
    return this.request('put', url, data);
  }

  delete(url, data) {
    return this.request('delete', url, data);
  }

  request(method, url, data) {
    /*    let token = localStorage.getItem('JWT');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };*/
    return axios({
      method,
      url: `${this.backendUrl}${url}`,
      data,
      withCredentials: true,
    })
      .then((response) => {
        if (response.data.status === 'error' && !response.data.errors) {
          response.data.errors = [];
        }
        return response.data;
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          loginStorage.logout();

          //          store.commit('setCurrentUser', null);
          router.push('/login');
        } else {
          if (e.message) {
            return { errors: [{ msg: e.message }] };
          }
        }
        return null;
      });
  }
}

export default new API();
