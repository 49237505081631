<template>
  <div>
    <v-list>
      <v-list-item
        v-for="(merchandise, index) in merchandiseList"
        :key="'merchandise' + index"
        style="margin-bottom:12px;"
        @click="merchandiseClicked(index)"
      >
        <v-list-item-content>
          <div>
            <h2 style="margin-bottom:12px;">{{ merchandise.link }}</h2>
            <div>{{ merchandise.category }}</div>
            <div>{{ merchandise.subcategory }}</div>
          </div>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn @click.stop="deleteMerchandiseConfirmation(merchandise)" icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn></v-list-item-action
        >
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-btn class="mr-4 main-btn success-green" text @click="addCustomDate()">
        Add Product
      </v-btn>
    </v-card-actions>
    <v-dialog v-if="dialog" v-model="dialog" max-width="400">
      <Merchandise
        :merchandise="currentMerchandise"
        :categories="categories"
        @saved="merchandiseClosed"
        @close="dialog = false"
    /></v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Delete merchandise?
        </v-card-title>
        <v-card-text>This operation can not be undone</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-4"
            text
            @click="(dialogDelete = false), deleteMerchandise()"
          >
            Delete
          </v-btn>
          <v-btn color="green darken-1" text @click="dialogDelete = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-debugger */
import API from '../../api';
import Merchandise from './Merchandise';

export default {
  name: 'WorkingDays',
  props: {},
  components: {
    Merchandise,
    //Matching,
    //Tags,
  },
  data: () => ({
    error: null,
    merchandiseList: [],
    categories: [],
    categoriesMap: {},
    currentMerchandise: {},
    dialog: false,
    dialogDelete: false,
  }),
  async created() {
    this.loadData(this.sessionType);
  },
  mounted() {
    document.title = 'Edit Working Days';
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData();
    next();
  },
  methods: {
    async loadData() {
      this.merchandiseList = [];
      let res = await API.get('merchandise');
      this.merchandiseList = res.data.merchandise;
      this.categories = res.data.categories;
      this.categoriesMap = {};
      for (let index = 0; index < this.categories.length; index++) {
        const category = this.categories[index];
        this.categoriesMap[category.id] = category;
      }
      //sdfsd;
    },
    merchandiseClicked(index) {
      this.currentMerchandise = Object.assign({}, this.merchandiseList[index]);
      this.dialog = true;
    },
    addCustomDate() {
      this.currentMerchandise = {};
      this.dialog = true;
    },
    async merchandiseClosed() {
      await this.loadData();
      this.dialog = false;
    },
    async deleteMerchandise() {
      if (this.currentMerchandise.id) {
        await API.delete('merchandise', { id: this.currentMerchandise.id });
        await this.loadData();
      }
    },
    deleteMerchandiseConfirmation(merchandise) {
      this.currentMerchandise = merchandise;
      this.dialogDelete = true;
    },
  },
};
</script>

<style>
.dow {
  width: 60px;
  display: inline-block;
}
.dowCheckbox {
  margin-top: 0px;
}
</style>
